import React from "react";
import { BrowserRouter as Router, Routes, Route, useParams, Navigate } from "react-router-dom";
import Booking from "../pages/Booking/Booking";
import Activities from "../pages/activities/ActivityPage";
import TravelAgencyPage from "../pages/travelagencies/TravelAgencyPage";
import Accomodation from "../pages/accomodations/AccomodationPage";
import PointOfInterest from "../pages/pointofinterests/PointOfInterestPage";
import TouristAttraction from "../pages/touristattractions/TouristAttractionPage";

const HomeWrapper = () => {
  const { home } = useParams();
  // Verifica si el parámetro `home` es válido o redirige si es necesario.
  if (home && home !== "travel-agencies") {
    return <Navigate to="/" replace />;
  }
  return <TravelAgencyPage home={home || ""} />;
};

const AppRoutes = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomeWrapper />} />
        <Route path="/:travel-agencies" element={<HomeWrapper />} />
        <Route path="/booking" element={<Booking />} />
        <Route path="/activities" element={<Activities />} />
        <Route path="/accomodations" element={<Accomodation />} />
        <Route path="/point-of-interests" element={<PointOfInterest />} />
        <Route path="/tourist-attractions" element={<TouristAttraction />} />
      </Routes>
    </Router>
  );
};

export default AppRoutes;

