import React, { useRef, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSuitcase,
  faBed,
  faStreetView,
  faBuildingColumns
} from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const TabServices = ({ tabIndex, lang }) => {
  const containerRef = useRef(null);
  // Refs para el manejo del drag y para evitar clics no deseados
  const isDraggingRef = useRef(false);
  const startXRef = useRef(0);
  const scrollLeftRef = useRef(0);
  const preventClickRef = useRef(false);

  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    if (lang && i18n.language !== lang) {
      i18n.changeLanguage(lang); // Cambia el idioma dinámicamente
    }
  }, [lang, i18n]);

  // Cada vez que cambie el tab seleccionado, se asegura de que sea visible
  useEffect(() => {
    if (containerRef.current && containerRef.current.children[tabIndex]) {
      containerRef.current.children[tabIndex].scrollIntoView({
        behavior: "smooth", // Desplazamiento animado
        inline: "center",   // Centra horizontalmente el tab
        block: "nearest"    // No afecta el scroll vertical
      });
    }
  }, [tabIndex]);

  const handleStart = (e) => {
    isDraggingRef.current = true;
    // Guardamos la posición inicial, considerando eventos táctiles o de mouse
    startXRef.current = e.touches ? e.touches[0].pageX : e.pageX - containerRef.current.offsetLeft;
    scrollLeftRef.current = containerRef.current.scrollLeft;
    // Reseteamos la bandera de clic
    preventClickRef.current = false;
    containerRef.current.style.cursor = "grabbing";
    document.body.style.userSelect = "none"; // Evita la selección de texto
  };

  const handleMove = (e) => {
    if (!isDraggingRef.current) return;
    const x = e.touches ? e.touches[0].pageX : e.pageX - containerRef.current.offsetLeft;
    const walk = x - startXRef.current;
    // Si el movimiento es mayor a 5 píxeles, lo consideramos un drag y evitamos el clic
    if (Math.abs(walk) > 5) {
      preventClickRef.current = true;
    }
    containerRef.current.scrollLeft = scrollLeftRef.current - walk;
  };

  const handleEnd = () => {
    isDraggingRef.current = false;
    containerRef.current.style.cursor = "grab";
    document.body.style.userSelect = ""; // Habilita la selección de texto nuevamente
  };

  const handleTabClick = (path) => {
    // Navegar a la ruta específica con el idioma en la URL
    navigate(`/${path}?lang=${lang}`);
  };

  const styles = {
    container: {
      display: "flex",
      flexDirection: "row",
      overflow: "hidden",
      whiteSpace: "nowrap",
      cursor: "grab",
      userSelect: "none", // Evita la selección dentro del contenedor
    },
    tab: (isSelected) => ({
      display: "flex",
      flexDirection: "column",
      borderRadius: "8px",
      margin: "0 4px",
      padding: "16px",
      backgroundColor: isSelected ? "white" : "transparent",
      border: isSelected ? "1px solid #ccc" : "1px solid transparent",
      boxShadow: isSelected ? "0 2px 4px rgba(0, 0, 0, 0.1)" : "none",
      cursor: "pointer",
    }),
    icon: {
      fontSize: "24px",
      textAlign: "center",
    },
    label: {
      textAlign: "center",
      fontWeight: "bold",
      fontSize: "14px",
    },
  };

  return (
    <div
      ref={containerRef}
      className="col-xxl-8 col-xl-8 col-lg-8 col-md-8 col-sm-8 col-xs-12 mt-1"
      style={styles.container}
      onMouseDown={handleStart}
      onMouseMove={handleMove}
      onMouseUp={handleEnd}
      onMouseLeave={handleEnd}
      onTouchStart={handleStart}
      onTouchMove={handleMove}
      onTouchEnd={handleEnd}
    >
      {/* Tab para Tours */}
      <div
        style={styles.tab(tabIndex === 0)}
        onClick={(e) => {
          if (preventClickRef.current) {
            preventClickRef.current = false; // Resetea la bandera
            return;
          }
          handleTabClick("travel-agencies");
        }}
      >
        <div style={styles.icon}>
          <FontAwesomeIcon icon={faSuitcase} />
        </div>
        <div style={styles.label}>{t("tab_index_1")}</div>
      </div>

      {/* Tab para Alojamiento */}
      <div
        style={styles.tab(tabIndex === 1)}
        onClick={(e) => {
          if (preventClickRef.current) {
            preventClickRef.current = false;
            return;
          }
          handleTabClick("accomodations");
        }}
      >
        <div style={styles.icon}>
          <FontAwesomeIcon icon={faBed} />
        </div>
        <div style={styles.label}>{t("tab_index_2")}</div>
      </div>

      {/* Tab para Puntos de Interés */}
      <div
        style={styles.tab(tabIndex === 2)}
        onClick={(e) => {
          if (preventClickRef.current) {
            preventClickRef.current = false;
            return;
          }
          handleTabClick("point-of-interests");
        }}
      >
        <div style={styles.icon}>
          <FontAwesomeIcon icon={faStreetView} />
        </div>
        <div style={styles.label}>{t("tab_index_3")}</div>
      </div>

      {/* Tab para Atracciones Turísticas */}
      <div
        style={styles.tab(tabIndex === 3)}
        onClick={(e) => {
          if (preventClickRef.current) {
            preventClickRef.current = false;
            return;
          }
          handleTabClick("tourist-attractions");
        }}
      >
        <div style={styles.icon}>
          <FontAwesomeIcon icon={faStreetView} />
        </div>
        <div style={styles.label}>{t("tab_index_4")}</div>
      </div>

      {/* Puedes agregar más tabs si lo requieres */}
    </div>
  );
};

export default TabServices;
