import {useState, useEffect} from "react";
import { useSelector } from "react-redux";
import PointOfInteresetRepository from "../repositories/PointOfInterestRepository";

const usePointOfInterests = (t_id = 0) => {
    const { latitude, longitude } = useSelector((state) => state.location);
    const { skip, limit, sort } = useSelector((state) => state.search);
    const [pointOfInterests, setPointOfInterests] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchPointOfInterests = async () => {
            setLoading(true);
            try {
                const response = await PointOfInteresetRepository.getAllPointsOfInterest({
                    lat: latitude,
                    lng: longitude,
                    t_id,
                    skip,
                    limit,
                    sort,
                });
                console.log("API Response:", response);

                setPointOfInterests((prev) => {
                    if (skip === 0) {
                        return response || [];
                    } else {
                        return [...prev, ...(response || [])];
                    }
                });
            } catch (err) {
                console.error("Error fetching point of interests:", err);
                setError(err);
            } finally {
                setLoading(false);
            }
        };

        fetchPointOfInterests();
    },[latitude, longitude, skip, limit, sort]);
    return {pointOfInterests, loading, error};
};

export default usePointOfInterests;