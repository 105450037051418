import React from 'react';

const Footer = () => {
    const whatsappMessage ="¡Hola!. Quisiera anunciar mi negocio con ustedes. ¿Me informan?";
    const phoneNumber = "51953794518";
    // Función para rastrear clic en WhatsApp
    const handleContactWhatsApp = () => {
        // Redirigir al usuario a WhatsApp
        window.open(`https://wa.me/${phoneNumber}?text=${encodeURIComponent(whatsappMessage)}`, "_blank");
    };

    return (<>
            <div className="row justify-content-center">
                <button className="btn btn-primary w-100 p-2" onClick={handleContactWhatsApp}>Anuncia tu negocio con nosotros</button>
            </div>
            <div className={`row justify-content-center bg-floral-white`}>
                <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-8 col-sm-8 col-xs-12 d-flex justify-content-between align-items-center p-3 ms-2 me-2">
                    <p className="text-center text-n6x">Paracas.online forma parte de Maabi Software Solutions S.A.C., empresa de desarrollo de tecnologías de información y referente local en turismo en Paracas, comprometida con impulsar experiencias auténticas y memorables en nuestro destino.
                    Copyright © 2025 Paracas.online™. Todos los derechos reservados.</p>
                </div>    
            </div>
        </>
    )
}

export default Footer;