import React from "react";
import usePointOfInterests from "../../hooks/usePointOfInterests";
import { useSearchParams } from "react-router-dom";
import Loading from "../../components/Loading";
import TabServices from "../../components/TabServices";
import NavbarHeader from "../../components/NavbarHeader";
import PuntosDeIntereses from "../../components/PointOfInterest";
import Footer from "../../components/Footer";

const TouristAttractionPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const allowedLangs = ["es", "en", "fr","cn"]; // Valores permitidos
  let lang = searchParams.get("lang");

  // Validar el valor de lang
  if (!allowedLangs.includes(lang)) {
    lang = "es"; // Valor predeterminado
    setSearchParams({ lang: "es" }); // Actualiza la URL para incluir el valor predeterminado
  }

  // Obtenemos skip y limit del store para usarlos al cargar más
  const { pointOfInterests, loading, error } = usePointOfInterests(5);
  
  if (loading) return <Loading isLoading={true} />;
  
  if (error) {return (<p>No se pudieron cargar los establecimientos. Por favor, intenta nuevamente más tarde.</p>);}

  return (
    <div className="container-fluid">
      {/* Navbar */}
      <NavbarHeader lang={lang} />
      
      {/* Espaciado */}
      <div className="mt-1"></div>
      <div className="row justify-content-center">
        <TabServices tabIndex={3} lang={lang} />
      </div>

      {/* Lista de establecimientos */}
      <div className="row justify-content-center">
        {pointOfInterests.map((pointOfInterest) => (
          <PuntosDeIntereses data={pointOfInterest} lang={lang}/>
        ))}
      </div>

      {/* Espaciado */}
      <div className="mt-1"></div>

      {/* Footer */}
      <Footer />
    </div>
  );
};

export default TouristAttractionPage;
