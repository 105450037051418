import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";
import RouteMapModal from "./RouteMapModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faBuilding,faRoute,faCircleInfo,} from "@fortawesome/free-solid-svg-icons";

const PointOfInterest = ({ data, lang }) => {
    const { t, i18n } = useTranslation();
    const { latitude, longitude } = useSelector((state) => state.location); // Ubicación del usuario
    const [isMapOpen, setIsMapOpen] = useState(false); // Estado para mostrar/ocultar el mapa
    const distance = data.distance<1 ?  `${(data.distance*1000).toFixed(1)}mt` :  `${data.distance.toFixed(1)}km`;
    const walking = data.distance<2 ? true : false;

    const [isExpanded, setIsExpanded] = useState(false); // Estado para manejar la expansión

    // Cambiar idioma dinámicamente si `lang` cambia
    useEffect(() => {
        if (lang && i18n.language !== lang) {
            i18n.changeLanguage(lang);
        }
    }, [lang, i18n]);

    const handleOpenMap = () => {
        if (!latitude || !longitude) {
            return;
        }
        setIsMapOpen(true);
    };

    const handleCloseMap = () => {
        setIsMapOpen(false);
    };

    // Seleccionar el nombre según el idioma con fallback a `nameEs`
    const name = useMemo(() => {
        const langKey = `name${lang.charAt(0).toUpperCase() + lang.slice(1)}`;
        return data?.[langKey] || data?.nameEs || t("Unknown Point");
    }, [data, lang, t]);

    // Seleccionar el tipo según el idioma con fallback a `type.SingularEs`
    const type = useMemo(() => {
        const langKey = `singularName${lang.charAt(0).toUpperCase() + lang.slice(1)}`;
        return data?.type[langKey] || data?.type.singularNameEs || t("Unknown Point");
    }, [data, lang, t]);

    // Seleccionar la descripción según el idioma con fallback a `descriptionEs`
    const description = useMemo(() => {
        const langKey = `description${lang.charAt(0).toUpperCase() + lang.slice(1)}`;
        return data?.[langKey] || data?.descriptionEs || t("No description available");
    }, [data, lang, t]);

    // Estilos en línea para truncar la descripción a 3 líneas (cuando está contraída)
    const truncatedStyle = {
        display: '-webkit-box',
        WebkitLineClamp: 3, // Limita a 3 líneas
        WebkitBoxOrient: 'vertical',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: '100%',
        whiteSpace: 'normal',
    };

    // Estilos en línea para la imagen
    const imageStyle = {
        width: '100%',
        height: '150px',
        objectFit: 'cover', // Ajusta la imagen sin distorsionarla
        borderRadius: '8px', // Bordes redondeados
    };

    return (
        <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-8 col-sm-8 col-xs-12 card mt-1">
            <div className="card-body">
                {/* Información del Punto de Interés */}
                <div className="row justify-content-start">
                    <div className="col-12 d-flex flex-column mb-3">
                        <h5 className="text-uppercase text-start fw-bold text-royal-blue m-0 p-0">{name}</h5>
                        <p className="text-uppercase text-start fw-bold text-black m-0 p-0">{type}</p>
                    </div>
                </div>
                <div className="row justify-content-start">
                    <div className="col-4 d-flex flex-column mb-3">
                        {/*Agrega una imagen que ocupe todo el height segun el siguiente div*/}
                        {data.images.map((image, index) => (
                            <img
                            key={index}
                            src={image.imageUrl}
                            style={imageStyle}
                            alt={name}
                            />
                        ))}
                    </div>
                    <div className="col-8 d-flex flex-column mb-3">
                        <p style={isExpanded ? {} : truncatedStyle}>
                            {description}
                        </p>
                        {/* Botón Ver más / Ver menos */}
                        <button 
                            className="btn btn-link p-0 text-primary text-start" 
                            onClick={() => setIsExpanded(!isExpanded)}
                        >
                            {isExpanded ? "Ver menos" : "Ver más"}
                        </button>
                    </div>
                    <button
                        className="btn btn-outline-primary mx-1 mt-1"
                        onClick={handleOpenMap} // Abre el modal del mapa
                    >
                        <FontAwesomeIcon icon={faRoute} /> {t("distance")} - {distance}
                    </button>
                </div>
            </div>
            {/* Modal del mapa con la ruta */}
            {isMapOpen && (
                <RouteMapModal
                apiKey="AIzaSyAOZe_kwgfg12mP_c7D1oZYmFbyAagBpHs"
                origin={{ lat: latitude, lng: longitude }} // Ubicación del usuario
                destination={{ lat: data.latitude, lng: data.longitude }} // Ubicación del negocio
                onClose={handleCloseMap} // Cierra el modal
                walking={walking}
                />
            )} 
        </div>
    );
};

export default PointOfInterest;
