import React from 'react';

const Loading = ({ isLoading }) => {
  if (!isLoading) return null; // No se renderiza nada si isLoading es false

  return (
    <div style={styles.container}>
      <div style={styles.centerContent}>
        <p style={styles.appName}>
          <span className="fs-lilita-one-regular text-center text-white text-9x">
            Paracas
            <span style={styles.textYellow}>.online</span>
          </span>
        </p>
        <p style={styles.loadingText}>Espere por favor...</p>
      </div>
      <div style={styles.footer}>
        <p style={styles.footerText}>Powered By</p>
        <p style={styles.footerText}>Maabi Software Solutions S.A.C.</p>
      </div>
    </div>
  );
};

const styles = {
  container: {
    position: 'fixed', // Cubre toda la pantalla
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    backgroundColor: '#2E9AFE',
    color: '#fff',
    fontFamily: 'Arial, sans-serif',
    zIndex: 9999,
    display: 'flex',
    flexDirection: 'column',
  },
  centerContent: {
    flex: 1, // Ocupa el espacio disponible
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center', // Centrado vertical
    alignItems: 'center', // Centrado horizontal
  },
  footer: {
    paddingBottom: '20px', // Separación desde el borde inferior
    textAlign: 'center',
  },
  appName: {
    margin: 0,
    padding: 0,
    textAlign: 'center',
  },
  textYellow: {
    color: 'yellow',
  },
  loadingText: {
    margin: 0,
    padding: 0,
    textAlign: 'center',
  },
  footerText: {
    margin: 0,
    padding: 0,
  },
};

export default Loading;
