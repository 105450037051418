import React from "react";
import useWeather from "../hooks/useWeather";

const Weather = () => {
  const { weather, loading, error } = useWeather();

  if (loading) {
    return (
      <div className="text-white d-flex justify-content-start align-items-center">
        <div style={spinnerStyle}></div>
        <span style={{ marginLeft: "10px" }}>Cargando...</span>
      </div>
    );
  }

  if (error) {
    return <div className="text-danger">⚠️ {error}</div>;
  }

  if (!weather) {
    return <div className="text-warning">⚠️ No hay datos de clima disponibles.</div>;
  }

  return (
    <div className="d-flex flex-row align-items-center">
      <img className="p-0 my-n4 img-fluid" src={weather.weather_icon} width="40" alt="Icono del clima" />
      <span className="p-0 m-0 text-white text-n6x">{weather.current_temp_c}°C</span>
      <span className="px-1 py-1 m-0 text-white text-n6x">|</span>
      <span className="p-0 m-0 text-white text-n6x">{weather.current_temp_f}°F</span>
      <span>&nbsp;</span>
    </div>
  );
};

const spinnerStyle = {
  width: "24px",
  height: "24px",
  border: "4px solid rgba(255, 255, 255, 0.3)",
  borderTop: "4px solid white",
  borderRadius: "50%",
  animation: "spin 1s linear infinite",
  display: "inline-block",
};

// 🎥 Animación de rotación en el mismo componente
const styleTag = document.createElement("style");
styleTag.innerHTML = `
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;
document.head.appendChild(styleTag);

export default Weather;
