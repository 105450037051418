import apiClient from "../services/apiClient"; 
const PointOfInterestRepository = {
    getAllPointsOfInterest: async (params) => {
        const queryParams = Object.entries(params)
            .filter(([_, value]) => value != null)
            .map(([key, value]) => `${key}=${value}`)
            .join("&");
        const endpoint = queryParams ? `point-of-interests?${queryParams}` : "point-of-interests";
        try {
            const response = await apiClient.get(endpoint);
            return response.data;
        } catch (error) {
            console.error("Error fetching points of interest:", error);
            throw error;
        }
    }
};

export default PointOfInterestRepository;