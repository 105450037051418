import React from "react";
import useBusinesses from "../../hooks/useBusinesses";
import { useSearchParams } from "react-router-dom";
import Establecimiento from "../../components/Establecimiento";
import Loading from "../../components/Loading";
import TabServices from "../../components/TabServices";
import NavbarHeader from "../../components/NavbarHeader";
import Footer from "../../components/Footer";

const AccomodationPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const allowedLangs = ["es", "en", "fr","cn"]; // Valores permitidos
  let lang = searchParams.get("lang");

  // Validar el valor de lang
  if (!allowedLangs.includes(lang)) {
    lang = "es"; // Valor predeterminado
    setSearchParams({ lang: "es" }); // Actualiza la URL para incluir el valor predeterminado
  }

  const { businesses, loading, error } = useBusinesses(2);

  if (loading) return <Loading isLoading={true} />;
  
  if (error) {return (<p>No se pudieron cargar los establecimientos. Por favor, intenta nuevamente más tarde.</p>);}

  return (
    <div className="container-fluid">
      {/* Navbar */}
      <NavbarHeader lang={lang} />
      
      {/* Espaciado */}
      <div className="mt-1"></div>
      <div className="row justify-content-center">
        <TabServices tabIndex={1} lang={lang} />
      </div>

      {/* Lista de establecimientos */}
      <div className="row justify-content-center">
        {businesses.map((business) => (
          <Establecimiento business={business} apiKey={business.id} lang={lang}/>
        ))}
      </div>

      {/* Espaciado */}
      <div className="mt-1">&nbsp;</div>

      {/* Footer */}
      <Footer />
    </div>
  );
};

export default AccomodationPage;
