// useWeather.js
import { useState, useEffect } from "react";
import WeatherRepository from "../repositories/WeatherRepository";

const useWeather = () => {
  const [weather, setWeather] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchWeatherCondition = async () => {
      try {
        const response = await WeatherRepository.getCondition();
        console.log("API Response:", response);
        setWeather(response);
      } catch (err) {
        console.error("Error fetching weather:", err);
        setError("No se pudo obtener la información del clima.");
      } finally {
        setLoading(false);
      }
    };

    fetchWeatherCondition();
  }, []); // Se ejecuta solo una vez

  return { weather, loading, error };
};

export default useWeather;

