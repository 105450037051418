import apiClient from "../services/apiClient";

const WeatherRepository = {
  getCondition: async (params) => {
    const endpoint = "weather/conditions/now";
    
    try {
      const response = await apiClient.get(endpoint);
      return response.data;
    } catch (error) {
      console.error("Error fetching weather condition:", error);
      throw error;
    }
  },
};

export default WeatherRepository;



