import React, { useEffect, useState } from "react";
import { setLocation, setLocationError } from "../store/slices/locationSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Weather from "./Weather";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import GoogleMapModal from "./GoogleMapModal";
import LangModal from "./LangModal";
import { useDispatch, useSelector } from "react-redux";

const NavbarHeader = ({lang}) => {
    const dispatch = useDispatch();
    const [isMapModalOpen, setIsMapModalOpen] = useState(false);
    const [isLangModalOpen, setIsLangModalOpen] = useState(false);
    const [navbarClass, setNavbarClass] = useState("");
    const { latitude, longitude, error } = useSelector((state) => state.location);

    useEffect(() => {
        if(latitude==null && longitude==null){
          setIsMapModalOpen(true);
        }
      }, [dispatch]);

    useEffect(() => {
        // Cambiamos clase de la navbar según el estado del modal
        setNavbarClass(isMapModalOpen ? "" : "sticky-top");
        // Bloquear u habilitar el scroll
        document.body.style.overflow = isMapModalOpen ? "hidden" : "auto";
    }, [isMapModalOpen]);
    
    const handleLocationConfirm = () => {
        setIsMapModalOpen(false); // Configura el modal como cerrado
        // Forzar el scroll a 'auto' inmediatamente al confirmar
        document.body.style.overflow = "auto";
    };

    const handleMapModalOpen = () => { 
        setIsMapModalOpen(true); // Configura el modal como abierto
    }

    const handleLangModalOpen = () =>{
        setIsLangModalOpen(true);
    }

    if (isLangModalOpen) return <LangModal onClose={()=>{setIsLangModalOpen(false)}} />
    if (isMapModalOpen) return  <GoogleMapModal isOpen={isMapModalOpen} onClose={() => setIsMapModalOpen(false)} onConfirm={handleLocationConfirm} apiKey="AIzaSyAOZe_kwgfg12mP_c7D1oZYmFbyAagBpHs" lang={lang} />
    
    return (
        <div className={`row justify-content-center bg-royal-blue ${navbarClass}`}>
            <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-8 col-sm-8 col-xs-12 d-flex justify-content-between align-items-center p-3 ms-2 me-2">
                <span className="fs-lilita-one-regular text-center text-white text-5x">
                    Paracas<span className="text-yellow">.online</span>
                </span>
                <div className="d-flex align-items-center">
                    <Weather />
                    <img src={"/langs/"+lang+".png"} className="img-fluid" onClick={handleLangModalOpen}  width="30" alt="Logo" />
                    {/*<FontAwesomeIcon className="text-white text-10x" icon={faCircleUser} />*/}
                    <FontAwesomeIcon className="text-white text-10x ms-3" onClick={handleMapModalOpen} icon={faLocationDot} />
                </div>
            </div>
        </div>
    );
}

export default NavbarHeader;